<template>
<HelloWorld 
:style="cssVars"
@colorChange="cc"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'


export default {
  name: 'App',
  components: {
    HelloWorld
  },  
  data() {
    return {
      bgColorA: "rgb(244, 194, 194)",
      bgColorB: "rgb(242, 212, 162)",
      textColor: "rgb(12, 52, 77)",
      hovTextColor: "rgb(243, 203, 178)"
    }
  },
  computed: {
    cssVars() {
      return {
        '--bg-color-a': this.bgColorA,
        '--bg-color-b': this.bgColorB,
        '--text-color': this.textColor,
        '--hov-text-color': this.hovTextColor
      }
    }
  },
  methods: {
    cc: function(value){
      console.log(this.textColor);
      this.bgColorA = value.bgA;
      this.bgColorB = value.bgB;
      this.textColor = value.text;
      this.hovTextColor = value.hovText;
    }
  }
}
</script>

<style>
#app {
  margin: 0;
  font-family: 'Comic Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  display:flex;
}
</style>
