<template>
<div class="ree">
  <a href="https://lutentime.tumblr.com/"><p>tumblr</p></a>
  <a href="https://twitter.com/lutentime"><p>twitter</p></a>
  <a href="https://www.youtube.com/@lutentime"><p>youtube</p></a>
  <span 
  class="material-icons colorCircle" 
  :style="{color: colorA}"
  @click="editcolor('A')"
  >trip_origin</span>
  <span 
  class="material-icons colorCircle"
  :style="{color: colorB}"
  @click=" editcolor('B')"
  >trip_origin</span>

  <div v-if="showColors">
    <input type="range" min="0" max="255" 
    placeholder="r" v-model="A.r" class="slidr"/>
    <input type="range" min="0" max="255" 
    placeholder="g" v-model="A.g" class="slidg"/>
    <input type="range" min="0" max="255" 
    placeholder="b" v-model="A.b" class="slidb"/>
  </div>

  <div v-if="showColors">
    <input type="range" min="0" max="255" 
    placeholder="r" v-model="B.r" class="slidr"/>
    <input type="range" min="0" max="255" 
    placeholder="g" v-model="B.g" class="slidg"/>
    <input type="range" min="0" max="255" 
    placeholder="b" v-model="B.b" class="slidb"/>
  </div>

  <button v-if="showColors" @click="changecolor()">set</button>

</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      showColors: false,
      A: {r: 244, g: 194, b: 194},
      B: {r: 242, g: 212, b: 162}
    }
  },
  computed: {
    colorA() {
      return 'rgba(' + this.A.r + ',' + this.A.g + ',' + this.A.b + ', 1)';
    },
    colorB(){
      return 'rgba(' + this.B.r + ',' + this.B.g + ',' + this.B.b + ', 1)';
    }
  },
  methods: {
    editcolor(){
      this.showColors = !this.showColors;
    },
    changecolor(){
        var bgA = this.rgbaify(this.A.r, this.A.g, this.A.b);
        var bgB = this.rgbaify(this.B.r, this.B.g, this.B.b);
        var text = this.getTextColor(true);
        var hovText = this.getTextColor(false);
        this.$emit('colorChange', {bgA, bgB, text, hovText})
    },
    rgbaify(r, g, b){
      return 'rgba(' + r + ',' + g + ',' + b + ', 1)';
    },
    getTextColor(inv){
      var r = (+this.A.r + +this.B.r)/2;
      var g = (+this.A.g + +this.B.g)/2;
      var b = (+this.A.b + +this.B.b)/2;

      if(inv){
        r = Math.abs(255 - r);
        g = Math.abs(255 - g);
        b = Math.abs(255 - b);
      }
      return this.rgbaify(r, g, b);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ree{
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, var(--bg-color-a) 0%, var(--bg-color-b) 100%);
  height: 100vh;
  width: 100vw;
  margin: 0;
  vertical-align: top;
}
.material-icons.colorCircle{
   border-radius: 50%;
   cursor: pointer;
   border-style: dotted;
   border-color: rgba(0, 0, 0, 0.3);
   display: inline;
}
.slidr{
  accent-color: rgba(255, 0, 0);
}
.slidg{
  accent-color: rgba(0, 255, 0);
}
.slidb{
  accent-color: rgba(0, 0, 255);
}
input{
  margin: 5px;
  color:aliceblue;
  font-family: "Comic Sans";
  width: 4ch;
  font-size: 24px;
  background-color: rgba(125, 125, 125, 0.1);
  border: none;
  border-radius: 2px;
}
input:focus{
  outline: none;
}
input::placeholder{
  color: rgba(240, 248, 255, 0.3);
}
input[type="range" i]{
  appearance: none;
}

a:link, a:visited{
   text-decoration: none;
    color: var(--text-color)
}
a:hover, a:active{
    color: var(--hov-text-color)
}
button{
  margin: 5px;
  color:aliceblue;
  font-family: "Comic Sans";
  font-size: 24px;
  background-color: rgba(125, 125, 125, 0.1);
  border: none;
  border-radius: 2px;
}
h1 {
  display: inline;
  margin: 20px;
}
img{
  height: 30vh;
  width: auto;
}
</style>
